import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import WithLayout from '../WithLayout';
import { Broadsheet } from '../../components/Paragraph/Broadsheet';
import { BannerModule } from '../../components/Paragraph/BannerModule';
import { PhotoOfTheDayParagraph } from '../../components/Paragraph/PhotoOfTheDayParagraph';
import { HighVolumeHeroPackage } from '../../components/Paragraph/HighVolumeHeroPackage';
import { DynamicPackage } from '../../components/Paragraph/DynamicPackage';
import { ContentPackage } from '../../components/Paragraph/ContentPackage';
import { LeftAndRightContentPackage } from '../../components/Paragraph/LeftAndRightContentPackage';
import { VideoModule } from '../../components/Paragraph/VideoModule';
import { ContentHtmlPackage } from '../../components/Paragraph/ContentHtmlPackage';
import { PromoParagraph } from '../../components/Paragraph/PromoParagraph';
import { PromoBannerParagraph } from '../../components/Paragraph/PromoBannerParagraph';
import { AmbientPlayerModule } from '../../components/Paragraph/AmbientPlayerModule';
import { NewsletterModuleParagraph } from '../../components/Paragraph/NewsletterModuleParagraph';
import { AdInsert } from '../../components/responsive/atoms/Ad/AdInsert';
import { TvGuide } from '../../components/Paragraph/TvGuide';
import { Carousel } from '../../components/Paragraph/Carousel';
import { PromoCarousel } from '../../components/Paragraph/PromoCarousel';
import { VideoPlaylist } from '../../components/Paragraph/VideoPlaylist';
import { ImageCarousel } from '../../components/Paragraph/ImageCarousel';
import { OneUp } from '../../components/Paragraph/OneUp';
import { CardStack } from '../../components/Paragraph/CardStack';
import { PromoGridParagraph } from '../../components/Paragraph/PromoGridParagraph';

const nodeTypeHomepage = css`
  article& > div.node__cont > div {
    clear: both;
  }
`;

const Homepage = ({ content }) => {
  let adCounter = 0;
  return (
    <article
      data-node-id={content.id}
      // eslint-disable-next-line react/no-unknown-property
      about={content.url?.path}
      className={`node node--type-${content.bundle}`}
      css={nodeTypeHomepage}
    >
      {content.mainContent && (
        <div className="node__cont">
          {content.mainContent.map((paragraph, index) => {
            let adInsert = '';
            if ((index + 1) % 4 === 0) {
              adInsert = (
                <AdInsert
                  key={`homepage__${adCounter}`}
                  id={`homepage__${adCounter}`}
                  content={content}
                />
              );
              adCounter += 1;
            }
            const key = `${paragraph.entity.type}_${index}_${paragraph.entity.id}`;
            const isAboveTheFold = index === 0;
            switch (paragraph.entity.type) {
              case 'ParagraphHighVolumeHeroPackage':
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <HighVolumeHeroPackage
                      data={paragraph.entity}
                      content={content}
                      isAboveTheFold={isAboveTheFold}
                    />
                  </React.Fragment>
                );
              case 'ParagraphDynamicPackage1':
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <DynamicPackage
                      data={paragraph.entity}
                      content={content}
                      isAboveTheFold={isAboveTheFold}
                    />
                  </React.Fragment>
                );
              case 'ParagraphContentPackage1':
              case 'ParagraphContentPackage3':
              case 'ParagraphContentPackage6':
              case 'ParagraphContentPackage16':
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <ContentPackage
                      data={paragraph.entity}
                      content={content}
                      isAboveTheFold={isAboveTheFold}
                    />
                  </React.Fragment>
                );
              case 'ParagraphLeftAndRightContentPackage':
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <LeftAndRightContentPackage
                      data={paragraph.entity}
                      content={content}
                      isAboveTheFold={isAboveTheFold}
                      key={key}
                    />
                  </React.Fragment>
                );
              case 'ParagraphTvGuideElement':
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <TvGuide key={key} data={paragraph.entity} />
                  </React.Fragment>
                );
              case 'ParagraphBannerModule':
                return (
                  <BannerModule
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                    key={key}
                  />
                );
              case 'ParagraphBroadsheetModule': {
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <Broadsheet
                      data={paragraph.entity}
                      isAboveTheFold={isAboveTheFold}
                      key={key}
                    />
                  </React.Fragment>
                );
              }
              case 'ParagraphPhotoOfTheDay':
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <PhotoOfTheDayParagraph
                      title={paragraph.entity.title}
                      imageEntity={
                        paragraph.entity.photoGallery?.entity?.firstImage
                          ?.entity?.[0]
                      }
                      header={paragraph.entity.header}
                      photographer={paragraph.entity.photoCredit}
                      galleryUrl={
                        paragraph.entity.photoGallery?.entity?.url?.path
                      }
                      isAboveTheFold={isAboveTheFold}
                      key={key}
                      data={paragraph.entity}
                    />
                  </React.Fragment>
                );
              case 'ParagraphArticleVideoPlaylist':
                return (
                  <VideoPlaylist
                    data={paragraph.entity}
                    isAboveTheFold={index === 0}
                    key={key}
                  />
                );
              case 'ParagraphVideoModule':
                return (
                  <React.Fragment key={key}>
                    {adInsert}
                    <VideoModule
                      data={paragraph.entity}
                      content={content}
                      isAboveTheFold={isAboveTheFold}
                      key={key}
                    />
                  </React.Fragment>
                );
              case 'ParagraphAmbientPlayerModule':
                return (
                  <AmbientPlayerModule data={paragraph.entity} key={key} />
                );
              case 'ParagraphContentHtmlPackage':
                return <ContentHtmlPackage data={paragraph.entity} key={key} />;
              case 'ParagraphPromo':
                return <PromoParagraph {...paragraph.entity} key={key} />;
              case 'ParagraphPromoBanner':
                return (
                  <PromoBannerParagraph
                    {...paragraph.entity}
                    key={key}
                    isAboveTheFold={isAboveTheFold}
                  />
                );
              case 'ParagraphNewsletterModule':
                return (
                  <NewsletterModuleParagraph
                    data={paragraph.entity}
                    key={key}
                  />
                );
              case 'ParagraphCardstack':
                return (
                  <CardStack
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                    key={key}
                  />
                );
              case 'ParagraphCarousel':
                return (
                  <Carousel
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                    key={key}
                  />
                );
              case 'ParagraphDisneyPlus':
                return (
                  <PromoCarousel
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                    key={key}
                    type="disney_plus"
                  />
                );
              case 'ParagraphStarPlus':
                return (
                  <PromoCarousel
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                    key={key}
                    type="star_plus"
                  />
                );
              case 'ParagraphImageCarousel':
                return (
                  <ImageCarousel
                    key={key}
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                  />
                );
              case 'ParagraphOneUp':
                return (
                  <OneUp
                    key={key}
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                  />
                );
              case 'ParagraphPromoGrid':
              case 'ParagraphManualPromoGrid':
                return (
                  <PromoGridParagraph
                    key={key}
                    data={paragraph.entity}
                    isAboveTheFold={isAboveTheFold}
                    content={content}
                  />
                );
              default:
                break;
            }
            return null;
          })}
        </div>
      )}
    </article>
  );
};

Homepage.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export default WithLayout(Homepage);
